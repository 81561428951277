import store from '@tenant/core/init/store'
import { GUARD } from '@tenant/utils/constants'

const centralGuard = async (to, from, next) => {
    if (to.meta.guard === GUARD.CENTRAL) {
        if (store.getters.hasTenantToken) {
            next('/')

            return
        }

        if (!store.getters.hasCentralToken) {
            next({ name: 'auth.login' })

            return
        }

        if (!store.getters.isActiveUser && to.name !== 'auth.verify-code') {
            next({ name: 'auth.verify-code' })

            return
        }
    }

    next()
}

export default centralGuard
